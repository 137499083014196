.boleto-content {
  height: calc(100% - 135px); // height and header padding + footer
  margin-top: 93px;
  padding: 0 30px 2px;
  & > div > div {
    .card {
      height: 100%;
      .card-header {
        padding-top: 0;
      }
      .boleto-filter {
        height: calc(100% - 180px);
      }
      .boleto-no-filter {
        height: calc(100% - 155px);
      }
    }
    &:nth-child(2) {
      div {
        div {
          height: 100%;
        }
      }
    }
  }
  .filters-active {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 6px;
    max-height: 35px;
  }
  
  .filters-active > div > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .filters-active > div > ul li { 
    display: inline; 
    padding: 7px;
  }
}