.custom-content-receitas {
  display: flex;
  flex-direction: column;
  margin-top: 93px;
  padding: 0 30px 0px;
  height: calc(100% - 35px - 61px);
  .header-container {
    padding-bottom: 40px;
    height: auto;
  }
  .receita-content {
    height: calc(100% - 182px); // height and header padding + footer
    & > div {
      .card {
        height: 100%;
      }
      &:nth-child(2) {
        div {
          div {
            height: 100%;
          }
        }
      }
    }
  }
}