.from-check,
.form-check-radio {
    margin-bottom: 12px;
    position: relative;
}

.form-check {
  padding-left: 0;
  margin-bottom: .5rem;

    .form-check-label{
        display: inline-block;
        position: relative;
        cursor: pointer;
        padding-left: 35px;
        line-height: 26px;
        margin-bottom: 0;
    }

    .form-check-sign{
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      display: flex;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
    }

    .form-check-sign::before,
    .form-check-sign::after {
        content: " ";
        display: inline-block;
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        cursor: pointer;
        border-radius: 6px;
        top: 0;
        background-color: #AAA7A4;
        -webkit-transition: opacity 0.3s linear;
        -moz-transition: opacity 0.3s linear;
        -o-transition: opacity 0.3s linear;
        -ms-transition: opacity 0.3s linear;
        transition: opacity 0.3s linear;
    }
    .form-check-sign::after {
        content: "\f00c";
        top: 4px;
        text-align: center;
        font-size: 15px;
        opacity: 0;
        color: #FFF;
        border: 0;
        background-color: inherit;
    }
    &.disabled{
        .form-check-label{
            color: $dark-gray;
            opacity: .5;
            cursor: not-allowed;
        }
    }

}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {

}

.form-check input[type="checkbox"],
.form-check-radio input[type="radio"]{
    opacity: 0;
    position: absolute;
    visibility: hidden;
}
.form-check input[type="checkbox"]:checked + .form-check-sign::after{
    opacity: 1;
}

.form-control input[type="checkbox"]:disabled + .form-check-sign::before,
.checkbox input[type="checkbox"]:disabled + .form-check-sign::after{
    cursor: not-allowed;
}

.form-check .form-check-label input[type="checkbox"]:disabled + .form-check-sign,
.form-check-radio input[type="radio"]:disabled + .form-check-sign{
    pointer-events: none !important;
}

.form-check-radio{
  margin-left: -3px;

    .form-check-label{
        padding-left: 2rem;
    }
    &.disabled{
        .form-check-label{
            color: $dark-gray;
            opacity: .5;
            cursor: not-allowed;
        }
    }
}

.form-check-radio .form-check-sign{
    // font-family: 'FontAwesome';
      font-family: "Font Awesome 5 Free";
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      &::before{
      font-size: 22px;
      content: "\f111";
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      position: absolute;
      opacity: .50;
      left: 5px;
      top: -5px;
    }
}

.form-check-label input[type="checkbox"]:checked + .form-check-sign:before{
    background-color: #66615B;
}

.form-check-radio input[type="radio"] + .form-check-sign:after,
.form-check-radio input[type="radio"] {
    opacity: 0;
    @include transition-opacity(0.3s, linear);
    content:" ";
    display: block;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
    content: "\f192";
    top: -5px;
    position: absolute;
    left: 5px;
    opacity: 1;
    font-size: 22px;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after{
    opacity: 1;
}


.form-check-radio input[type="radio"]:disabled + .form-check-sign::before,
.form-check-radio input[type="radio"]:disabled + .form-check-sign::after {
    color: $dark-gray;
}
