.table .btn {
  margin: 2px;
}

.new-expense-modal {
  min-width: 900px;
}

.custom-incomes-modal {
  min-width: 80%;
}

.custom-incomes-modal-content {
  max-height: 430px;
  overflow: hidden;
  overflow-y: scroll; 
}

.dynamic-minus-button {
  margin-top: 28px;
}

.round-btn-add {
  margin-left: 12px;
}

.card-filtro-Relatorios {
  padding: 0px 15px 15px 15px;
}

.icon-card-filtro-Relatorios {
   display: inline; 
   padding: 0 11px;
   color: #023047;
   cursor: pointer;
}

.title-collapse-Relatorios {
  font-size: 14px; 
  display: inline;
}

.space-card-filter {
  margin: 10px 0px 10px;
}

.p-parcela {
  margin-top: 37px;
}

.font-badge {
  font-size: 13px;
}

@media (max-width: 768px) {
  .new-expense-modal {
    min-width: 100% !important;
  }
}

.boleto-modal {
  min-width: 1000px !important;
}

.modal85 {
  min-width: 85% !important;
}

.check-liquidacao {
  margin-top: 20px;
}

.col-Stats {
  padding-left: 1px;
  padding-right: 0px;
  margin: 10px 10px 10px 0px;
  max-width: 290px;
}

.logo-attivilog {
  height: 40px;
}

.centralizarBotao {
  margin-top: 26px;
}

.nav-parent {
  margin: 10px 0 0 15px;
  display: block;
  position: relative;
  font-size: 12px;
  padding-top: 10px;
  line-height: 30px;
}

.nav-parent button {
  cursor: pointer;
}

.nav-parent-collapsed {
  height: 40px;
  margin-left: 5px !important;
}

.nav-parent ul {
  padding-left: 10px;
}

.nav-parent button {
  background-color: #212120;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  width: 95%;
}

.nav-parent button:hover {
  border: none;
  outline: none;
  width: 95%;
}

.nav-parent button:focus {
  border: none;
  outline: none;
}

.nav-parent button i {
  color: #E63946 !important;
  float: left
}

.nav-parent button p {
  color: #FFFFFF !important;
  float: left;
  font-weight: 600;
  letter-spacing: 1px;
}

.nav-link {
  opacity: 1 !important;
}

.nav-link i {
  color: #E63946 !important;
}

.nav-link p {
  color: #FFFFFF !important;
  font-weight: 600;
  letter-spacing: 1px;
}


.nav-parent button:hover i, .nav-parent button:hover p {
  color: rgba(255, 255, 255, 0.8) !important;
}

.nav-parent .nav-link:hover p, .nav-parent .nav-link:hover i {
  color: rgba(255, 255, 255, 0.8) !important;
}

#arrow {
  float: right;
  margin-right: 0px;
}

.arrow-rotate-minus-90 {
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.arrow-rotate-0 {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.expiration-icon {
  font-size: 18px;
  margin-right: 7px;
}

.color-yellow {
  color: rgb(163, 163, 1);
}

.color-red {
  color: red;
}

.color-green {
  color: green;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-top-15 {
  margin-top: 15px;
}

.text-center {
  text-align: center;
}

/* Login Screen */

.background-attivilog {
  background: rgb(235,133,47);
  background: radial-gradient(circle, #01527a 0%, #023047 50%); 
}

.background-white {
  background: white;
}

.alert-dark {
  background-color: #f04943 !important;
}

.center-panel {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -225px;
  margin-left: -200px;
  width: 400px;
  text-align: center;
  padding: 35px 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.75);
}

.center-panel input {
  margin-bottom: 1rem;
  border: none;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  padding: 0.5rem;
  width: 100% !important;
}

.center-panel label {
  color: #023047;
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 0.15rem;
}

.center-panel button {
  font-size: 15px;
}

.spinner-login {
  width: 10rem;
  height: 10rem;
  align-self: center;
}

.link-forgot-password {
  background: none;
  border: none;
  color: #E63946;
  font-size: 0.85rem;
}

.recovery-text {
  font-size: 1rem !important;
  margin: 1rem 0 !important ;
  text-align: center;
  max-width: 380px;
}

.recovery-message {
  font-size: 0.85rem !important;
  margin: 1rem 0 !important ;
  text-align: center;
  max-width: 380px;
  align-self: center;
  color: #E63946;
}

.card-info {
  padding: 0 0.65rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-logo {
  font-size: 40px;
  display: block;
}

.app-login {
  position: relative;
}

.app-login p {
  display: inline-block;
}

.app-login img {
  height: 60px;
}

.logo-img {
  display: inline-block;
  margin-right: 15px;
  margin-top: -15px;
}

.login-text {
  font-size: 25px;
}

.hide {
  display: none;
}

.month-select {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

.empresa-logo {
  width: 150px;
  height: 150px;
  margin: 10px auto;
  border: 2px solid #bfc4ca;
  position: relative;
}

.logo-placeholder {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.logo-edit {
  border: none;
  border-radius: 3px;
  background-color: silver;
  color: whitesmoke;
  position: absolute;
  bottom: 0;
  right: 0;
}

.logo-upload {
  width: 150px;
}

.dropzone-error {
  color: red;
  font-size: 16px;
  margin-top: 10px;
}

.bg-gray {
  background-color: #cfd8dc;
  font-size: 12px;
}

.padding10 {
  padding: 10px;
}

.sidebar-wrapper {
  padding-bottom: 10px !important;
}

.sidebar-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 10px;
}

.sidebar-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.top-element div {
  z-index: 1060 !important;
}

.table-responsive {
  min-height: 300px !important;
}

.logout-button {
  margin-left: 15px;
  background: none;
  border: none;
}

.logout-button:hover {
  color: #888;
}

.logout-button i {
  font-size: 20px;
}

.logout-button p {
  margin-left: 5px;
  font-size: 15px;
}

.disabled-select>div {
  background-color: #e3e3e3;
}

.disabled-select>div>div>div {
  color: #736e69;
}

.expanded-sidebar {
  width: 270px !important;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.collapsed-sidebar {
  width: 65px !important;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.menu-collapse {
  float: right;
  font-size: 20px;
  margin-top: 12px;
  margin-right: 10px;
}

.menu-expand {
  float: left;
  font-size: 20px;
  margin: 12px 10px 0 10px;
}

@media (max-width: 991px) {
  .menu-collapse {
    display: none;
  }
  .menu-expande {
    display: none;
  }
}

.shrink-button {
  color: #E63946;
  font-size: 20px;
  cursor: pointer;
}

.show-element {
  opacity: 1;
  transition: opacity 1.5s linear !important;
  position: inherit;
}

.hide-element {
  opacity: 0 !important;
  position: absolute !important;
}

.hide-tenant-home {
  opacity: 0;
  display: none;
}

.collapse-panel {
  width: calc(100% - 65px);
}

.routes-panel {
  transform: translate(0, -105px);
  -moz-transform: translate(0, -105px);
  -webkit-transform: translate(0, -105px);
  position: fixed;
  width: 220px;
  min-height: 50px;
  background-color: #2c2c2c;
  left: 64px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #023047;
}

.routes-panel-normal-group {
  transform: translate(0, -40px);
  -moz-transform: translate(0, -40px);
  -webkit-transform: translate(0, -40px);
}

.collapsed-nav-link {
  display: block;
  margin: 15px 0 0 20px;
}

.collapsed-nav-link p {
  color: #e2e2e2;
  float: left;
  font-weight: 400;
  text-transform: uppercase;
}

.routes-group {
  margin: 10px 10px 10px 20px;
}

.routes-group p {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #e2e2e2;
}

.routes-group ul {
  padding-left: 0px;
}

.subroute-link {
  margin: 0 !important;
  padding: 0 !important;
}

.subroutes-separator {
  left: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: #e2e2e2;
}

.subroutes-group {
  margin-top: 10px;
}

.force-lower {
  text-transform: lowercase !important;
}

.badge-attivilog {
  font-size: 11px;
  padding-bottom: 5px;
}

.badge-attivilog i {
  font-size: 15px;
  margin-right: 5px;
  position: relative;
}

.badge-attivilog-green {
  background-color: green !important;
  color: white !important;
}

.badge-attivilog-green i {
  top: 5px;
}

.badge-attivilog-red {
  background-color: red !important;
  color: white !important;
}

.badge-attivilog-blue {
  background-color: #007bff !important;
  color: white !important;
}

.badge-attivilog-yellow {
  background-color: #e7c21b !important;
  color: white !important;
}

.dre-table {
  overflow: hidden;
}

.agrupamentos {
  font-weight: 600;
  padding: 8px !important;
}

.dre-expand {
  cursor: pointer;
  font-size: 25px !important;
  padding: 0 !important;
}

.initial-month-balance {
  text-align: center;
  font-weight: bolder;
}

@media(min-width: 768px) {
  .align-bottom {
    bottom: 0px;
    position: absolute;
    margin-bottom: 0;
  }
}

.conhecimento-liquidacao-info {
  display: block;
}

.conhecimento-liquidacao-info button {
  display: inline;
  margin-right: 10px;
}

.conhecimento-liquidacao-info p {
  display: inline;
}

.cte-table {
  max-height: 300px !important;
  overflow-y: scroll;
}

.thumbs-button {
  padding: 10px;
  margin: 0px !important;
  border-radius: 0px;
  font-size: 15px;
  font-weight: 600px;
}

.thumbs-icon {
  font-size: 12px;
}

.card-danger {
  background-color: #ef8157;
  color: white;
  font-weight: 600;
}

.card-success {
  background-color: #6bd098;
  color: white;
  font-weight: 600;
}

.desconsider_person_input {
  margin-bottom: 7px !important;
}

.checkbox_considerar_inativos {
  margin-top: 10px !important;
}

.checkbox_relatorio_movimentacoes {
  margin-top: 10px !important;
}

.label_checkbox_relatorio_movimentacoes {
  font-size: 14px !important;
  color: #252422 !important;
}

.grupo_pessoa_Col {
  margin-bottom: 7px;
}

.custom-content {
  display: flex;
  flex-direction: column;
  margin-top: 93px;
  padding: 0 30px 15px;
  height: calc(100% - 35px - 99px);
}

.custom-content > div:nth-child(2) > div > div {
  height: 100%;
}

.custom-content > div:nth-child(2) {
  height: calc(100% - 58px);
}

.reset-col {
  flex: 1 1;
}

.div-date-picker {
  margin-top: -10px;
  color: white !important;
}

.new-person-modal {
  min-width: 1024px;
}

.banck-transfer-red-margin {
  color: red;
  margin-left: 10px;
}

.banck-transfer-red {
  color: red;
}

.banck-transfer-green-margin {
  color: green;
  margin-left: 10px;
}

.banck-transfer-green {
  color: green;
}

.selectTableRow {
  margin-top: 20px;
}

.step-navigation {
  margin-top: 100px;
}

.step-card {
  min-height: 450px;
  overflow: hidden;
}

.step-info {
  font-size: large;
  margin-bottom: 10px;
}

.remessa-text {
  margin-top: 14px;
}

.checkedGif {
  max-width: 10%;
}

.undoButton {
  margin-right: 130px;
  margin-left: 15px;
}

.plano-pessoa-item-container {
  display: flex;
  margin: 1rem 0;
}

.plano-pessoa-item-form-column {
  padding : 1rem;
  display: flex;
  flex : 8;
  border: 1px solid #5D6E77;
  border-right: 0;
  flex-direction: column;
  border-radius: 0.8rem 0rem 0rem 0.8rem;
}

.plano-pessoa-item-button-column {
  display: flex;
  flex : 1;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #5D6E77;
  border-left: 0;
  border-radius: 0rem 0.8rem 0.8rem 0rem;
}

.custom-success-collor {
  color: #28a745 !important;
}

.custom-card-category {
  font-weight: 400;
  color: #9A9A9A;
  font-size: 1em; 
}

.personStatusBadge {
  height: "20px"; 
  margin-top: "2%"; 
  margin-right: "10px";
}

.flex {
  display: flex;
}

.card-grey {
  background-color: #bdbdbd;
}

.thumbs-color-blue {
  background-color: #2196f3;
}

.thumbs-color-blue:hover {
  background-color: #1565c0 !important;
}

.thumbs-color-blue:focus {
  background-color: #1565c0 !important;
}

.thumbs-color-red {
  background-color: #e53935;
}

.thumbs-color-red:hover {
  background-color: #c62828 !important;
}

.thumbs-color-red:focus {
  background-color: #c62828 !important;
}

.conciliacao-card {
  max-width: 800px;
  min-width: 800px;
  min-height: 400px;
  margin-left: auto;
  margin-right: auto;
}

.conhecimento-liquidacao-card {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 9px;
}

.custom-row-conhciemnto-liquidacao {
  margin-top: 15px;
}

.conciliation-day-badge {
  margin-left: 20px;
  font-size: 12px;
}

.records-select-table {
  font-size: 13.5px;
}

.zero-padding {
  padding: 0;
}

.search-person-select {
  width: 30%;
}

.custom-btn-link-color {
  color: #252422;
}

.custom-btn-link-color:hover {
  background-color: #403D39 !important;
  color: #FFFFFF !important;
  box-shadow: none !important;
}

.custom-btn {
  border-width: 2px;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 1.35em;
  text-transform: uppercase;
  border: none;
  margin: 10px 1px;
  border-radius: 3px;
  padding: 1px 22px;
  cursor: pointer;
  transition: all 150ms linear;
  background-color: unset !important;
  color: unset !important;
}

.pointer {
  cursor: pointer;
}

.modal-90w {
  width: 90%;
  max-width: none!important;
  margin-left: 6rem!important;
}

.resumo-veiculos {
  margin-top: 40px;
}

.check-boleto {
  margin-top: 20px;
}

.custom-btn-datepicker {
  width: 245px;
}

.bold-card {
font-weight: 700;
}

.btn-gear {
  border-width: 2px;
  font-weight: 600;
  font-size: 0.8571em;
  line-height: 1.35em;
  text-transform: uppercase;
  border: none;
  margin: 0px 0px;
  border-radius: 3px;
  padding: 0px 0px;
  width: 38px;
  height: 32px;
  cursor: pointer;
  transition: all 150ms linear;
  color: white;
}

.btn-without-margin{
  margin: 0px 0px;
}

.thead-height-15 {
  height: 60px;
  line-height: normal;
  vertical-align: middle;
}

.btn-size-10{
  font-size: 10px;
}

.custom-content-mt70{
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  padding: 0 30px 15px;
}

.margin-top-10 {
  margin-top: 10px;
}

.card-receita{
  overflow: auto; 
  height: calc(100vh - 140px);
}

.card-bloqueio-cliente{
  overflow: auto; 
  height: calc(100vh - 140px);
}

.modal-resumo-receita{
  min-width: 1040px;
}

.history-modal {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date-picker-input {
  width: 100%;
  padding: 8px; 
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  letter-spacing: 2px;
  color: #66615B;
}

.date-picker-input:disabled{
  border: none;
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.custom-person-link {
  font-size: 14px;
  border: none;
  background: none;
  text-align: left;
  color: #ec6730;
  font-weight: 600;
  cursor: pointer;
  padding: 0;
}

.agrupamentos.receita {
  border-left: 12px solid #28a745;
}

.agrupamentos.receita.expanded {
  font-weight: 400;
}

.agrupamentos.despesa {
  border-left: 12px solid #f04943;
}

.agrupamentos.info {
  border-left: 12px solid #2196f3;
}

.receita-bar {
  width: 100%;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.receita-bar .bars {
  width: 100% !important;
}

.receita-bar .bars .bar {
	float: left;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  max-width: 66%;
}

.receita-bar .bars .bar span {
  font-weight: bold;
  color: white;
}

.receita-bar .bars div.bar:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.receita-bar .bars div.bar:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.receita-bar .legends {
	text-align: left;
}


.receita-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
}

.receita-bar .legends .legend .dot {
	font-size: 25px;
	vertical-align: middle;
}

.receita-bar .legends .legend .label {
	margin-left: 2px;
	vertical-align: middle;
}

.receitas-totais {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  color: white;
}

.liquidadas {
  background-color: #6bd098;  
}

.vencer {
  background-color: #fbc658;
}

.primary-background {
  background-color: #023047 !important;
}

.secondary-background {
  background-color: #E63946 !important;
}

.color-secondary {
  color: #E63946 !important;
}

.header-block {
  display: contents;
}

.header-greetings {
  float: left;
  margin-right: 20px !important;
}

.header-white {
  color: white !important;
}

.header-company-select {
  float: left;
  width: 200px;
  margin-right: 15px; 
}

.company-select{
  max-height: 40px;
  overflow-y: auto;
}

textarea.form-control.textarea-pessoa-observacao {
  height: 110px;
  max-height: 110px;
}

.text-align-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.alteracoes-list {
  position: relative;
  padding-left: 10px;
}

.alteracoes-list::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  background-color: #9f9f9f;
}

.transition-container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.transition-container.visible {
  max-height: 1000px;
  opacity: 1;
}

.font-semibold {
  font-weight: 600;
}

.hidden-hist {
  display: none;
}

.value-alt {
  color: #333;
}

.flex-hist {
  display: flex;
  align-items: center;
}

.margin-vertical-small {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.margin-horizontal-medium {
  margin-left: 1rem;
  margin-right: 1rem;
}

.text-secondary-hist {
  color: #6c757d;
}