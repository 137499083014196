/* THIS FILE MAY ONLY HAVE TAILWINDCSS CLASSES  https://tailwindcss.com/docs/ */
/* Display */
.flex	{
  display: flex;
}

/* Flex Direction */
.flex-col	{
  flex-direction: column;
}

/* Align Items */
.items-end {
  align-items: flex-end;
}

/* Margin */

.m-0 {
  margin: 0;
}

/* Padding */
.p-2	{
  padding: 0.5rem;
}

.pt-2	{
  padding-top: 0.5rem;
}

.pr-2	{
  padding-right: 0.5rem;
}

.pb-2	{
  padding-bottom: 0.5rem;
}

.pl-0	{
  padding-left: 0;
}